import iconFacebook from '../assets/images/social/s-facebook.svg'
import iconTwitter from '../assets/images/social/s-twitter.svg'
import iconLinkedin from '../assets/images/social/s-linkedin.svg'
import iconTelegram from '../assets/images/social/s-telegram.svg'
import iconYoutube from '../assets/images/social/s-youtube.svg'
import iconDiscord from '../assets/images/social/s-discord.png'
import iconReddit from '../assets/images/social/s-reddit.png'
import iconMedium from '../assets/images/social/s-medium.png'
import iconGithub from '../assets/images/social/s-github.png'
import image01 from '../assets/images/image-01.png'
import image02 from '../assets/images/image-02.png'
import image03 from '../assets/images/image-03.png'
import image04 from '../assets/images/image-04.png'
import image05 from '../assets/images/image-05.png'
import image06 from '../assets/images/image-06.png'
import image07 from '../assets/images/image-07.png'
import icon03 from '../assets/images/icon-03.svg'
import icon04 from '../assets/images/icon-04.svg'
import icon06 from '../assets/images/icon-06.svg'
import icon07 from '../assets/images/icon-07.png'
import glass01 from '../assets/images/glass-01.png'
import glass02 from '../assets/images/glass-02.png'
import bgGlass02 from '../assets/images/bg-glass-02.png'
import iconIn1 from '../assets/images/icon-in1.png'
import iconIn2 from '../assets/images/icon-in2.png'
import iconIn3 from '../assets/images/icon-in3.png'
import iconShield from '../assets/images/icon-shield.png'
import iconDiamond from '../assets/images/icon-diamond.png'
import iconMount from '../assets/images/icon-mount.png'
import iconStar from '../assets/images/icon-star.png'
import slide1 from '../assets/images/slide1.jpg'
import slide2 from '../assets/images/slide2.jpg'
import slide3 from '../assets/images/slide3.jpg'
import iconPre from '../assets/images/icon-pre.png'
import iconNext from '../assets/images/icon-next.png'
import logo01 from '../assets/images/logo-01.png'
import logo02 from '../assets/images/logo-02.png'
import logo03 from '../assets/images/logo-03.png'
import logo04 from '../assets/images/logo-04.png'
import logo05 from '../assets/images/logo-05.png'
import logo06 from '../assets/images/logo-06.png'
import logo07 from '../assets/images/logo-07.png'
import logo08 from '../assets/images/logo-08.png'
import logo09 from '../assets/images/logo-09.png'
import logo10 from '../assets/images/logo-10.png'
import logo11 from '../assets/images/logo-11.png'
import logo12 from '../assets/images/logo-12.png'
import logo13 from '../assets/images/logo-13.png'
import logo14 from '../assets/images/logo-14.png'
import iconDivider from '../assets/images/divider.png'
import rocket from '../assets/images/rocket.jpg'
import iconLogo from '../assets/images/icon-logo-d.png'
import imgBuilds from '../assets/images/buidls.png'
import imgPools from '../assets/images/pools.png'
import { useEffect } from 'react'

function Home(props) {
useEffect(() => {
    let node = document.querySelector('#recipeCarousel')
    let items = node.querySelectorAll('.carousel-item')

    items.forEach((el) => {
      const minPerSlide = 4
      let next = el.nextElementSibling
      for (var i=1; i<minPerSlide; i++) {
        if (!next) {
          // wrap carousel by using first child
          next = items[0]
        }
        let cloneChild = next.cloneNode(true)
        el.appendChild(cloneChild.children[0])
        next = next.nextElementSibling
      }
    })
},[])
  return (
    <>
      <section id="introduction">
        <div className="content-width">
          <article className="row">
            <div className="col-12 col-sm-7">            
              <h1 className="animate three">Web3 IMO Fundraising Ecosystem</h1>
              <p className="animate four">
              Explore BUIDLs & create or join in an IMO pool to raise/invest funds 
              proportionately at end of each pre-decided milestone deployed on blockchain.
              </p>
              <div className="text_a">
                <a href="mailto:partnership@dstarter.io" className="btn-a btn-a-o animate five">
                  Get In Touch
                </a>
                <a href="/buidls" className="btn-a animate six">
                 Explore Buidls
                </a>
              </div>
              <ul className="fanpages animate seven">
                <li>
                  <a href="https://t.me/dstarter" target="_blank">
                    <img src={iconTelegram} alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/Dstarterio" target="_blank">
                    <img src={iconTwitter} alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/dstarter/" target="_blank">
                    <img src={iconLinkedin} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCoSCZbc9ur4Lxag6mkO--WA"
                    target="_blank"
                  >
                    <img src={iconYoutube} alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://discord.gg/ncA8c64yMb" target="_blank">
                    <img src={iconDiscord} alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://medium.com/@dstarter" target="_blank">
                    <img src={iconMedium} alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://www.reddit.com/user/dstarterio" target="_blank">
                    <img src={iconReddit} alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://github.com/timestarter/time-daofund" target="_blank">
                    <img src={iconGithub} alt="" />
                  </a>
                </li>
              </ul>
              <div className="clearfix"></div>
            </div>            
            <div className="col-12 col-sm-5">
              <div className="img-intro">
                <img className="img-fluid animate four" src={image01} alt="" />
                <img className="img-fluid abs abs-2 animate one" src={glass02} alt="" />
                <img className="img-fluid abs abs-7 animate three" src={glass01} alt="" />
                <div className="opacity">
                  <span className="span_text abs abs-3 key_frame_4">#Web3</span>
                  <span className="span_text abs abs-4 key_frame_3">#DAO</span>
                  <span className="span_text abs abs-5 key_frame_5">#Decentralize-Fund</span>
                  <span className="span_text abs abs-6 key_frame_4">#MilestoneOffering</span>
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>
      <section id="buidls">
        <div className="content-width">
          <div className="title-home">
            <div className="row">
              <div className="col-12 col-sm-7">
                <h2>Buidls</h2>
                <hr />
                <p>
                  A dedicated space for developers. Reach out to our communities and investor groups by submitting a BUIDL now!
                </p>
              </div>
              <div className="col-12 col-sm-5">
                <div className="text_a">
                  <a href="/buidls" className="btn-a">
                    Explore More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="public-builds">
            <img className="img-fluid" src={imgBuilds} alt="" />
            {/* <div className="row">
              <div className="col-12 col-sm-3">
                <div className="rel-img">
                  <img src={rocket} alt="rocket" />
                  <div className="box-abs-d">
                    <img src={iconLogo} alt="icon-logo" />
                    <h3>
                      <span>Creator</span>
                      Buidler
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-9">
                <h4>Your Buidl</h4>
                <p>
                  DStarter empowers startup to publish their project details on the platform in just a few clicks. However, only verified projects have the ability to 
                  create a pool for fundraising.<br /><br />
                  To submit a Buidl, Connect Wallet > Verify your email address > Choose your role as a "Startup" > Complete KYC in few minutes > Follow the steps
                  and fill in the information in "Create a new Buidl". After creating the Buidl, startup can check the startup and manage them in their dashboard.
                </p>
                <ul>
                  <li>GameFi</li>
                  <li>Web3</li>
                  <li>Chain</li>
                  <div className="clearfix"></div>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section id="pool-home" className="space_section">
        <div className="content-width">
          <div className="title-home text-center">
            <h2>Upcoming Pools</h2>
            <hr />
            <div className="text_a">
              <a href="#" className="btn-a btn-a-o">
                Create a Pool
              </a>
              <a href="/pool" className="btn-a">
                Explore More
              </a>
            </div>
          </div>

          <div className="public-pools">
            <img className="img-fluid" src={imgPools} alt="" />
          </div>
        </div>
      </section>
      <section id="about-us" className="space_section">
        <div className="content-width">
          <div className="row">
            <div className="col-12 col-sm-5">
              <div className="rel-img">
                <img className="img-fluid upload-main" src={image02} alt="" />
                <img className="img-fluid abs-bg" src={bgGlass02} alt="" />
              </div>
            </div>
            <div className="col-12 col-sm-7">
              <article>
                <div className="title-home">
                  <h2>Escrowed Fundraising</h2>
                  <hr />
                </div>
                <h3>
                 Why IMO?
                </h3>
                <p>
                DStarter is a DAO-governed, smart-contracts escrowed fundraising platform for Web3 VCs 
                & startups based on milestone-completion (Initial Milestone Offering for startups & VCs).
                In simpler words, DStarter is platform where startups can submit their "Buidls", and if 
                approved, they can create a pool to host "Initial Milestone Offering". <br /><br />
                The term, IMO is coined from the fundraising concept existing in our platform, that enables
                startups to raise funds through a pool in proportion wise as they keep on achieving their 
                pre-defined milestones that were deployed on blockchain. The whole process of IMO (Initial 
                Milestone Offering) is governed by our native ImoDAO. <br /><br />In IMO, instead of allocating the funds 
                completely at once, this model allows the investors to vote and release the funds at different
                 timeline required to achieve the next milestone. These milestones are measurable goals 
                 pre-decided and deployed on blockchain.<br /><br />
                </p>
                <div className="box-invest">
                  <p>Empowering:</p>
                  <div className="row">
                    <div className="col-4">
                      <img src={iconIn1} alt="" />
                      <small>Investors</small>
                    </div>
                    <div className="col-4">
                      <img src={iconIn2} alt="" />
                      <small>Teams</small>
                    </div>
                    <div className="col-4">
                      <img src={iconIn3} alt="" />
                      <small>Communites</small>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>

          <div className="row focus">
            <div className="col-6 col-sm-3">
              <article>
                <img src={iconShield} alt="" />
                <p>A safe and transparent Fundraising environment</p>
              </article>
            </div>
            <div className="col-6 col-sm-3">
              <article>
                <img src={iconMount} alt="" />
                <p>DAO, Milestone offering</p>
              </article>
            </div>
            <div className="col-6 col-sm-3">
              <article>
                <img src={iconDiamond} alt="" />
                <p>Objective valuation</p>
              </article>
            </div>            
            <div className="col-6 col-sm-3">
              <article>
                <img src={iconStar} alt="" />
                <p>Investors ranking score</p>
              </article>
            </div>
          </div>

          {/*<img className="img-fluid mg-auto" src="/images/diagram.png" alt="" /> */}
        </div>
      </section>
      <section id="features">
        <div className="bg-grid">
          <div className="content-width">
            <div className="title-home">
              <h2>Ecosystem</h2>
              <hr />
            </div>
            <article className="row">
              <div className="col-12 col-sm-6">
                <ul>
                  <li>
                    <span>
                      <img src={icon07} alt="" />
                      IMO DAO
                    </span>
                    <small>
                      DAO Governance allows investors to be more involved in decision making, use of
                      funds while keeping their investment safe. At the same time startups don't
                      have to focus on fundraising and focus on what they do best - Buidl, to make
                      sure they continue to receive the funding as they go on to achieve milestones.
                    </small>
                  </li>
                  <li>
                    <span>
                     <img src={icon03} alt="" />              
                      Secured
                    </span>
                    <small>
                      Our audit partners and teams make sure the DStarter smart contracts work
                      safely and securely.
                    </small>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-sm-6">
                <div className="group_img">
                  <div className="row">
                    <div className="col-5">
                      <img className="img-fluid pos-01" src={image03} alt="" />
                    </div>
                    <div className="col-7">
                      <img className="img-fluid" src={image04} alt="" />
                      <img className="img-fluid pos-02" src={image05} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article className="row artic02">
              <div className="col-12 col-sm-6 order-bottom">
                <div className="group_img">
                  <div className="row">
                    <div className="col-5">
                      <img className="img-fluid pos-01" src={image06} alt="" />
                    </div>
                    <div className="col-7">
                      <img className="img-fluid pos-02" src={image07} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 order-top">
                <ul className="mt-5">
                  <li>
                    <span>
                      <img src={icon06} alt="" />
                      Multichain
                    </span>
                    <small>
                      Initially, our platform is supporting multiple EVM compatible chains like Ethereum, 
                      Polygon, OKC & BNBChain. The testing and development of various other layer 1 blockchains 
                      is in progress too. <br /><br />This would not only help us to expand the scope of our ecosystem but 
                      also create more opportunities for our stakeholders!
                    </small>
                  </li>
                </ul>
                <ul className="mt-5">
                  <li>
                    <span>
                      <img src={icon04} alt="" />
                      Explore Web3 VCs & Startups
                    </span>
                    <small>
                      Investors & startups have dedicated pages on our platform, even those startups
                      who aren’t eligible to create pools yet.
                    </small>
                  </li>
                </ul>
              </div>
            </article>
          </div>
        </div>
      </section>
      <section id="dao-funding" className="space_section">
        <div className="content-width">
          <div id="carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carousel"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carousel"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carousel"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>

            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <article>
                      <h6>
                        <img src="images/icon-logo.png" />
                        We help with
                      </h6>
                      <h3>IMO DAO FUNDING</h3>
                      <hr />
                      <p>
                        Early-stages fundraising platform based on DAO and milestone-offering model.
                      </p>
                    </article>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="box-img-abs">
                      <img className="img-fluid" src={slide1} alt="" />
                      <span>Live</span>
                      <img className="abs-icon" src="images/icon-logo.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <article>
                      <h6>
                        <img src="images/icon-logo.png" />
                        We help with
                      </h6>
                      <h3>Fundraising</h3>
                      <hr />
                      <p>Create, manage and join investment pools based on the DAO model.</p>
                    </article>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="box-img-abs">
                      <img className="img-fluid" src={slide2} alt="" />
                      <span>Live</span>
                      <img className="abs-icon" src="images/icon-logo.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <article>
                      <h6>
                        <img src="images/icon-logo.png" />
                        We help with
                      </h6>
                      <h3>Incubation</h3>
                      <hr />
                      <p>
                        Incubating high quality startups. Raise funds from best investors. Get our
                        support for:
                      </p>
                      <p>
                        I. Tokenomics: Token structure, anti-inflation formulas, vesting schedule,
                        liquidity incentivisation, etc.
                      </p>
                      <p>
                        II. Go To Market: Documentation, strategy, revenue model, community, KOLs,
                        beta-testing, TGE etc.{' '}
                      </p>
                      <p>
                        III. Legal Support: Our legal partners can support you in registration,
                        investment contracts (SAFTs), finance, etc.
                      </p>
                    </article>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="box-img-abs">
                      <img className="img-fluid" src={slide3} alt="" />
                      <span>Live</span>
                      <img className="abs-icon" src="images/icon-logo.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="carousel-item">
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <article>
                        <h6>
                          <img src="images/icon-logo.png" /> Main Services
                        </h6>
                        <h3>INCUBATOR</h3>
                        <hr />
                        <p>Blockchain startup-as-a-service</p>
                      </article>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="box-img-abs">
                        <img className="img-fluid" src={slide4} alt="" />
                        <span>Live</span>
                        <img className="abs-icon" src="images/icon-logo.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <article>
                        <h6>
                          <img src="images/icon-logo.png" /> Main Services
                        </h6>
                        <h3>DLANCER</h3>
                        <hr />
                        <p>DAO career marketplace platform</p>
                      </article>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="box-img-abs">
                        <img className="img-fluid" src={slide5} alt="" />
                        <span>Live</span>
                        <img className="abs-icon" src="images/icon-logo.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div> */}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carousel"
              data-bs-slide="prev"
            >
              <img src={iconPre} alt="" />
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carousel"
              data-bs-slide="next"
            >
              <img src={iconNext} alt="" />
            </button>
          </div>
        </div>
      </section>
      <section id="partner" className="space_section">
        <div className="content-width">
          <div className="title-home text-center">
            <h2>Partnerships</h2>
            <hr />
          </div>
          
          
          <div id="onlyMobile" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="row">
                  <div className="col-6">
                   <a href="https://www.xt.com/" target="_blank"><img src={logo13} className="img-fluid" /></a>
                  </div>
                  <div className="col-6">
                    <a href="https://blackpearlvc.org/" target="_blank"><img src={logo14} className="img-fluid" /></a>
                  </div>
                  <div className="col-6">
                    <a href="https://bmoon.io/" target="_blank"><img src={logo03} className="img-fluid" /></a>
                  </div>
                  <div className="col-6">
                    <a href="https://www.vesting.co/" target="_blank"><img src={logo04} className="img-fluid" /></a>                               
                  </div>
                  <div className="col-6">
                    <a href="https://zexprwire.com/" target="_blank"><img src={logo05} className="img-fluid" /></a>                                
                  </div>
                  <div className="col-6">
                   <a href="https://www.lbank.info/" target="_blank"><img src={logo06} className="img-fluid" /></a>
                  </div> 
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-6">
                    <a href="https://www.bitrue.com/home/" target="_blank"><img src={logo07} className="img-fluid" /></a>
                  </div>
                  <div className="col-6">
                   <a href="https://www.digifinex.com/" target="_blank"><img src={logo08} className="img-fluid" /></a>
                  </div>
                  <div className="col-6">
                   <a href="https://www.okx.com/" target="_blank"><img src={logo09} className="img-fluid" /></a>
                  </div>
                  <div className="col-6">
                    <a href="https://www.bitmart.com/" target="_blank"><img src={logo10} className="img-fluid" /></a>
                  </div>
                  <div className="col-6">
                    <a href="https://www.huobi.com/en-us/" target="_blank"><img src={logo11} className="img-fluid" /></a>
                  </div>
                  <div className="col-6">
                   <a href="https://www.mexc.com/" target="_blank"><img src={logo12} className="img-fluid" /></a>
                  </div>
                </div>
              </div>              
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#onlyMobile" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#onlyMobile" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>



          <div id="recipeCarousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner" role="listbox">
            {/*<div className="carousel-item active">
                    <div className="col-12 col-md-3">
                        <div className="card">
                            <div className="card-img">
                               <a href="https://kiwigroup.com.vn/" target="_blank"><img src={logo01} className="img-fluid" /></a>
                            </div>                            
                        </div>
                    </div>
              </div>*/}
                <div className="carousel-item active">
                    <div className="col-12 col-md-3">
                        <div className="card">
                            <div className="card-img">
                              <a href="https://www.xt.com/" target="_blank"><img src={logo13} className="img-fluid" /></a>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="col-12  col-md-3">
                        <div className="card">
                            <div className="card-img">
                              <a href="https://blackpearlvc.org/" target="_blank"><img src={logo14} className="img-fluid" /></a>
                            </div>                            
                        </div>
                    </div>
                </div>  
                <div className="carousel-item">
                    <div className="col-12 col-md-3">
                        <div className="card">
                            <div className="card-img">
                                <a href="https://www.huobi.com/en-us/" target="_blank"><img src={logo11} className="img-fluid" /></a>
                            </div>                            
                        </div>
                    </div>
                </div>   
                <div className="carousel-item">
                    <div className="col-12 col-md-3">
                        <div className="card">
                            <div className="card-img">
                                <a href="https://www.okx.com/" target="_blank"><img src={logo09} className="img-fluid" /></a>
                            </div>                            
                        </div>
                    </div>
                </div>           
                <div className="carousel-item">
                    <div className="col-12  col-md-3">
                        <div className="card">
                            <div className="card-img">
                               <a href="https://bmoon.io/" target="_blank"><img src={logo03} className="img-fluid" /></a>                                
                            </div>                            
                        </div>
                    </div>
                </div>                
                <div className="carousel-item">
                    <div className="col-12 col-md-3">
                        <div className="card">
                            <div className="card-img">
                                <a href="https://www.bitmart.com/" target="_blank"><img src={logo10} className="img-fluid" /></a>
                            </div>                            
                        </div>
                    </div>
                </div>
                
                <div className="carousel-item">
                    <div className="col-12 col-md-3">
                        <div className="card">
                            <div className="card-img">
                                <a href="https://www.mexc.com/" target="_blank"><img src={logo12} className="img-fluid" /></a>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="col-12  col-md-3">
                        <div className="card">
                            <div className="card-img">
                               <a href="https://www.vesting.co/" target="_blank"><img src={logo04} className="img-fluid" /></a>                               
                            </div>                           
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="col-12 col-md-3">
                        <div className="card">
                            <div className="card-img">
                             <a href="https://zexprwire.com/" target="_blank"><img src={logo05} className="img-fluid" /></a>                                
                            </div>                            
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="col-12 col-md-3">
                        <div className="card">
                            <div className="card-img">
                                <a href="https://www.lbank.info/" target="_blank"><img src={logo06} className="img-fluid" /></a>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="col-12 col-md-3">
                        <div className="card">
                            <div className="card-img">
                                <a href="https://www.bitrue.com/home/" target="_blank"><img src={logo07} className="img-fluid" /></a>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="col-12 col-md-3">
                        <div className="card">
                            <div className="card-img">
                                <a href="https://www.digifinex.com/" target="_blank"><img src={logo08} className="img-fluid" /></a>
                            </div>                            
                        </div>
                    </div>
                </div>                
                <div className="carousel-item">
                    <div className="col-12 col-md-3">
                        <div className="card">
                            <div className="card-img">
                               <a href="https://maxx.capital/" target="_blank"><img src={logo02} className="img-fluid" /></a>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
            <a className="carousel-control-prev bg-transparent w-aut" href="#recipeCarousel" role="button" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            </a>
            <a className="carousel-control-next bg-transparent w-aut" href="#recipeCarousel" role="button" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
            </a>
          </div> 

        </div>
      </section>
      {/* <section id="learn" className="space_section">
        <div className="content-width">
          <div className="bg-title">
            <div className="title-home">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <h2>
                    Learn more about <span>DStarter</span>
                  </h2>
                  <hr />
                </div>
                <div className="col-12 col-sm-6">
                  <div className="text_a">
                    <a
                      href="https://docs.dstarter.io/"
                      target="_blank"
                      className="btn-a"
                    >
                      Explore More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion accordion-flush" id="accordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-head01">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-01"
                    aria-expanded="false"
                    aria-controls="flush-01"
                  >
                    <img src={iconDivider} alt="" /> How to participate in a pool?
                  </button>
                </h2>
                <div
                  id="flush-01"
                  className="accordion-collapse collapse show"
                  aria-labelledby="flush-head01"
                  data-bs-parent="#accordion"
                >
                  <div className="accordion-body">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                    when an unknown printer took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but also the leap into
                    electronic typesetting, remaining essentially unchanged. It was popularised in
                    the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                    and more recently with desktop publishing software like Aldus PageMaker
                    including versions of Lorem Ipsum.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-head02">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-02"
                    aria-expanded="false"
                    aria-controls="flush-02"
                  >
                    <img src={iconDivider} alt="" /> How to submit a BUIDL?
                  </button>
                </h2>
                <div
                  id="flush-02"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-head02"
                  data-bs-parent="#accordion"
                >
                  <div className="accordion-body">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                    when an unknown printer took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but also the leap into
                    electronic typesetting, remaining essentially unchanged. It was popularised in
                    the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                    and more recently with desktop publishing software like Aldus PageMaker
                    including versions of Lorem Ipsum.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-head03">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-03"
                    aria-expanded="false"
                    aria-controls="flush-03"
                  >
                    <img src={iconDivider} alt="" /> What is IMO?
                  </button>
                </h2>
                <div
                  id="flush-03"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-head03"
                  data-bs-parent="#accordion"
                >
                  <div className="accordion-body">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                    when an unknown printer took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but also the leap into
                    electronic typesetting, remaining essentially unchanged. It was popularised in
                    the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                    and more recently with desktop publishing software like Aldus PageMaker
                    including versions of Lorem Ipsum.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section id="interested">
        <div className="bg-img">
          <div className="content-width">
            <div className="row">
              <div className="col-12 col-sm-6">
                <article>
                  <div className="title-home">
                    <h2>Get in touch!</h2>
                    <hr />
                  </div>
                  <p>
                    partnership@dstarter.io <br /> 
                    support@dstarter.io
                  </p>
                </article>
              </div>
              <div className="col-12 col-sm-6"></div>
            </div>
          </div>
        </div>
      </section>

     
    </>
  )
}

export default Home
