import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ConnectAccountButton } from 'components/common/connect-wallet/ConnectWalletButton'
import iconPerson from 'assets/images/icon-person.png'
import iconGrip from 'assets/images/icon-grid.png'
import iconChart from 'assets/images/icon-grid.png'
import iconVote from 'assets/images/icon-vote.png'
import iconClaim from 'assets/images/icon-diamond-24x24.png'
import iconInvestorProfile from 'assets/images/icon-profile.png'
import iconInvestmentList from 'assets/images/investment-list.png'
import iconPools from 'assets/images/icon-pools.png'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { authActions, authSelector } from 'redux/reducers/authSlice'
import { userProfileSelector } from 'redux/reducers/userProfile'
import { chainSelector } from 'redux/reducers/networkSlice'
import { Drawer } from 'antd'
import { isMobile, deviceDetect } from 'react-device-detect'
import { useEffect } from 'react'
import { RegisterNewChain } from 'components/common/connect-wallet/RegisterNewChain'
import { SwitchChainModal } from 'components/common/connect-wallet/SwitchChainModal'
import { useSwitchChain } from 'components/hooks/useSwitchChain'
function ellipsisAddress(walletId) {
  return walletId
    ? walletId.substring(0, 5) + '...' + walletId.substring(walletId.length - 5, walletId.length)
    : ''
}

const HeaderLayout = () => {
  let location = useLocation()
  const { onSwitch } = useSwitchChain()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { currentNetwork } = useSelector(authSelector)
  const userProfile = useSelector(userProfileSelector)
  const network = useSelector(chainSelector)
  console.log('currentNetwork', network)
  const [listPathForStartUp, setListPathForStartUp] = useState([
    {
      key: 1,
      pathname: '/dashboard/my-profile',
      icon: iconPerson,
      name: 'My Profile',
    },
    {
      key: 2,
      pathname: '/dashboard/myproject',
      icon: iconGrip,
      name: 'My Buidls',
    },
    {
      key: 3,
      pathname: '/dashboard/pools',
      icon: iconPools,
      name: 'My Pools',
    },
    {
      key: 4,
      pathname: '/dashboard/startup/vote-detail',
      icon: iconVote,
      name: 'Vote Detail',
    },
    {
      key: 6,
      pathname: '/dashboard/claims',
      icon: iconClaim,
      name: 'Claim',
    },
  ])
  const [listPathForVC, setListPathForVC] = useState([
    {
      key: 1,
      pathname: '/dashboard/my-profile',
      icon: iconPerson,
      name: 'My Profile',
    },
    {
      key: 2,
      pathname: '/dashboard/investor/profile',
      icon: iconInvestorProfile,
      name: 'Investor Profile',
    },
    {
      key: 4,
      pathname: '/dashboard/investor/my-investment',
      icon: iconInvestmentList,
      name: 'Investment list',
    },
    {
      key: 3,
      pathname: '/dashboard/investor/vote-detail',
      icon: iconVote,
      name: 'Vote Detail',
    },
  ])
  const listNavBar = [
    {
      name: 'Home',
      path: '/',
    },
    {
      name: 'Buidls',
      path: '/buidls',
    },
    {
      name: 'VCs list',
      path: '/vcslist',
    },
    {
      name: 'Pools',
      path: '/pool',
    },
    // {
    //   name: 'Statistics',
    //   path: '/statistic',
    // },
    // {
    //   name: 'Incubator',
    //   path: '/incubator',
    // },
    {
      name: 'Blog',
      path: '/blog',
    },
    {
      name: 'FAQ',
      path: '/faq',
    },
  ]
  const [open, setOpen] = useState(false)
  const [isMobileView, setIsMobile] = useState(isMobile)
  const onClose = () => {
    setOpen(false)
  }

  const getMenuOfRole = () => {
    if (userProfile?.RoleStartup) {
      return listPathForStartUp
    } else if (userProfile?.RoleVC) {
      return listPathForVC
    }
    return []
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [])

  const handleResize = () => {
    const data = deviceDetect(window.navigator.userAgent)
    const value = data.isMobile ? data.isMobile : false
    setIsMobile(value)
  }
  const menuClick = (path) => {
    setOpen(false)
    navigate(path)
  }
  const onChainHandler = async (chain) => {
    onSwitch(chain)
  }

  const nav = () => {
    return (
      <nav id="navbar_main" className="navbar-expand-lg">
        <div className="content-width">
          <div className="offcanvas-header">
            <button
              className="btn-close btn-close-white float-end"
              onClick={() => {
                setOpen(false)
              }}
            ></button>
          </div>
          <a
            className="navbar-brand"
            onClick={() => {
              menuClick('/')
            }}
          >
            <img src={require('assets/images/logo.png').default} alt="" />
          </a>
          <ul className="navbar-nav ms-auto">
            {listNavBar.map((value, index) => {
              return (
                <li
                  key={index}
                  className={location.pathname === value.path ? 'nav-item active' : 'nav-item'}
                >
                  <a
                    className="nav-link"
                    onClick={() => {
                      menuClick(value.path)
                    }}
                  >
                    {value.name}
                  </a>
                </li>
              )
            })}
            <li className="nav-item">
              <a className="nav-link" href="https://docs.dstarter.io/" target="_blank">
                Docs
              </a>
            </li>
          </ul>

          <ul className="navbar-nav sign">
            {userProfile.WalletID ? (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="javascript:void(0);"
                  data-bs-toggle="dropdown"
                >
                  <img src={require('assets/images/icon-dash.png').default} alt="" /> Dashboard
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    {getMenuOfRole()
                      ? getMenuOfRole().map((value, key) => {
                          return (
                            <div
                              key={key}
                              className="dropdown-item"
                              onClick={() => {
                                setOpen(false)
                                navigate(`${value.pathname}`)
                              }}
                            >
                              <img src={value.icon} alt="" /> {value.name}
                            </div>
                          )
                        })
                      : null}
                  </li>
                </ul>
              </li>
            ) : (
              <></>
            )}
            {currentNetwork && userProfile.WalletID ? (
              <li className="nav-item dropdown multichain">
                <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">
                  <img src={currentNetwork.icon} alt="" /> {currentNetwork?.chainName}
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  {network && network.listChain ? (
                    <>
                      {network.listChain.map((item) => {
                        return (
                          <li key={item.id} onClick={() => onChainHandler(item)}>
                            <a className="dropdown-item">
                              <img src={item.icon} alt="" /> {item.chainName}
                            </a>
                          </li>
                        )
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
              </li>
            ) : (
              <></>
            )}
            <li className="nav-item dropdown">
              {userProfile.WalletID ? (
                <>
                  <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                    {ellipsisAddress(userProfile.WalletID)}
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          setOpen(false)
                          dispatch(authActions.globalLogout())
                        }}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </>
              ) : (
                <ConnectAccountButton />
              )}
            </li>
          </ul>
        </div>
      </nav>
    )
  }
  return (
    <>
      <header>
        <span className="screen-darken"></span>
        <div className="header-mobile">
          <a className="logo-mobile" href="/">
            <img src={require('assets/images/logo.png').default} alt="" />
          </a>
          <button
            id="btn-menu"
            data-trigger="navbar_main"
            className="d-lg-none"
            type="button"
            onClick={() => {
              setOpen(true)
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        {isMobileView ? (
          <Drawer width={300} closable={false} placement="left" onClose={onClose} visible={open}>
            {nav()}
          </Drawer>
        ) : (
          nav()
        )}
      </header>
      <RegisterNewChain />
      <SwitchChainModal />
    </>
  )
}
export default HeaderLayout
